import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Banner from 'components/banner';
import Layout from 'components/layout';
import Content from 'components/content/documentation/faq';

const Faq = ({ data }) => {
   const content = data?.pages?.edges?.[0]?.node;
  return (
  <Layout>
    <Helmet title={content.frontmatter.title} />
    <Banner {...content.frontmatter} />
    <Content {...content} />
  </Layout>
  )
}

export default Faq;

export const query = graphql`
	query FaqPageQuery ($path: String!) {
        ...MarkdownPages
    }
`